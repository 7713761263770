import axios from '@/plugins/axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getContents() {
        const { data } = await axios.get(`/contents`);

        return data;
    },

    async updateContentsBySection({ state }, { section, contents }) {
        const { data } = await axios.put('/contents', {
            section,
            contents
        });

        return data;
    }
};
