import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import NProgress from 'nprogress';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/specialists',
        name: 'specialists',
        component: () => import('@/views/specialists/Specialists'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/realizations',
        name: 'realizations',
        component: () => import('@/views/realizations/Realizations'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/technologies',
        name: 'technologies',
        component: () => import('@/views/technologies/Technologies'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/opinions',
        name: 'opinions',
        component: () => import('@/views/opinions/Opinions'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/opinions/new',
        name: 'new-opinion',
        component: () => import('@/views/opinions/AddOpinion'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/opinions/edit/:id',
        name: 'edit-opinion',
        component: () => import('@/views/opinions/AddOpinion'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/contents',
        name: 'contents',
        component: () => import('@/views/contents/Contents'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/settings/Settings'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => lazyLoadView(import('@/views/auth/Login')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut');
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    route => route.meta.authRequired
                );
                // Navigate back to previous page, or home as a fallback
                next(
                    authRequiredOnPreviousRoute
                        ? { name: 'dashboard' }
                        : { ...routeFrom }
                );
            }
        }
    },
    {
        path: '/404',
        name: '404',
        component: require('@/views/pages/_404').default,
        props: true,
        meta: {
            authRequired: true
        }
    },
    {
        path: '*',
        redirect: '404'
    }
];

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active-menu-item',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((routeTo, routeFrom, next) => {
    if (routeFrom.name !== null) {
        NProgress.start();
    }

    next();
    const authRequired = routeTo.matched.some(route => route.meta.authRequired);

    if (routeTo.name === 'login') {
        next();
    }

    if (!authRequired) return next();

    if (store.getters['auth/loggedIn']) {
        return store.dispatch('auth/validate').then(validUser => {
            validUser ? next() : redirectToLogin();
        });
    }

    redirectToLogin();

    function redirectToLogin() {
        next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            if (routeFrom.name === args[0].name) {
                                NProgress.done();
                            }
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }

    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;

function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        // loading: require('@views/pages/_loading').default,
        delay: 400,
        // error: require('@views/pages/_timeout').default,
        timeout: 10000
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        }
    });
}
