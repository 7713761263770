import axios from '@/plugins/axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getRealizations() {
        const { data } = await axios.get(`/realizations`);

        return data;
    },

    async addRealization({ state }, { realizationData, technologies }) {
        const { data } = await axios.post(`/realizations`, {
            realizationData,
            technologies
        });

        return data;
    },

    async updateRealization({ state }, { id, realizationData, technologies }) {
        const { data } = await axios.put(`/realizations/${id}`, {
            realizationData,
            technologies
        });

        return data;
    },

    async deleteRealization({ state }, id) {
        const { data } = await axios.delete(`/realizations/${id}`);

        return data;
    },

    async uploadImage({ state }, { image }) {
        const imageData = new FormData();

        imageData.append('image', image, image.name);

        const { data } = await axios.post(`/realizations/image`, imageData);

        return data;
    },

    async updateOrder({ state }, realizationData) {
        const { data } = await axios.put(`/realizations`, {
            realizationData
        });

        return data;
    }
};
