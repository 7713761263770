import axios from '@/plugins/axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getOpinions() {
        const { data } = await axios.get(`/opinions`);

        return data;
    },

    async getOpinion({ state }, id) {
        const { data } = await axios.get(`/opinions/${id}`);

        return data;
    },

    async addOpinion({ state }, opinionData) {
        const { data } = await axios.post(`/opinions`, opinionData);

        return data;
    },

    async updateOpinion({ state }, { id, opinionData }) {
        const { data } = await axios.put(`/opinions/${id}`, {
            opinionData
        });

        return data;
    },

    async deleteOpinion({ state }, id) {
        const { data } = await axios.delete(`/opinions/${id}`);

        return data;
    }
};
