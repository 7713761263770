import axios from '@/plugins/axios';

export const state = {
    currentUser: getSavedState('auth.currentUser')
};

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState('auth.currentUser', newValue);
        setDefaultAuthHeaders(state);
    }
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser;
    }
};

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch }) {
        setDefaultAuthHeaders(state);
        dispatch('validate');
    },

    // Logs in the current user.
    async logIn({ commit, dispatch, getters }, { email, password } = {}) {
        try {
            if (getters.loggedIn) {
                return dispatch('validate');
            }

            const { data } = await axios.post('/auth/login', {
                email,
                password
            });

            if (data !== undefined) {
                commit('SET_CURRENT_USER', data);
            }

            return data;
        } catch (error) {
            console.error(error);
        }
    },

    // Logs out the current user.
    async logOut({ commit }) {
        commit('SET_CURRENT_USER', null);

        await axios.post('/auth/logout');
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    async validate({ commit, state }) {
        try {
            if (!state.currentUser) {
                return Promise.resolve(null);
            }

            const { data } = await axios.get('/user/me');

            commit('SET_CURRENT_USER', data);

            return data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                commit('SET_CURRENT_USER', null);
            }

            return null;
        }
    }
};

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

function setDefaultAuthHeaders(state) {
    axios.defaults.headers.common.Authorization = state.currentUser
        ? state.currentUser.token
        : '';
}
