  <template>
    <div>
        <main-layout v-if="!isAuthLayout" />
        <auth-layout v-else />
    </div>
</template>

<script>
import MainLayout from '@/views/layouts/MainLayout';
import AuthLayout from '@/views/layouts/AuthLayout';

export default {
    components: {
        MainLayout,
        AuthLayout
    },

    computed: {
        isAuthLayout() {
            const authRoutes = ['/login'];

            return authRoutes.some(route => {
                return this.$route.fullPath.startsWith(route);
            });
        }
    }
};
</script>

<style lang="scss">

@import '@/assets/styles/main.scss';

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>