import axios from '@/plugins/axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getSpecialists() {
        const { data } = await axios.get(`/specialists`);

        return data;
    },

    async addSpecialist({ state }, specialistData) {
        const { data } = await axios.post(`/specialists`, {
            specialistData
        });

        return data;
    },

    async updateSpecialist({ state }, { id, specialistData }) {
        const { data } = await axios.put(`/specialists/${id}`, {
            specialistData
        });

        return data;
    },

    async deleteSpecialist({ state }, id) {
        const { data } = await axios.delete(`/specialists/${id}`);

        return data;
    },

    async uploadImage({ state }, { image }) {
        const imageData = new FormData();
        imageData.append('image', image, image.name);

        const { data } = await axios.post(`/specialists/image`, imageData);

        return data;
    },

    async updateOrder({ state }, specialistData) {
        const { data } = await axios.put(`/specialists`, {
            specialistData
        });

        return data;
    }
};
