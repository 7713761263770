import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import axios from '@/plugins/axios';
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import 'cropperjs/dist/cropper.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

window.axios = axios;
Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(Toasted, {
    theme: 'bubble',
    position: 'bottom-right',
    duration: 5000
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
