import axios from '@/plugins/axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getTechnologies() {
        const { data } = await axios.get(`/technologies`);

        return data;
    },

    async addTechnology({ state }, technologyData) {
        const { data } = await axios.post(`/technologies`, {
            technologyData
        });

        return data;
    },

    async updateTechnology({ state }, { id, technologyData }) {
        const { data } = await axios.put(`/technologies/${id}`, {
            technologyData
        });

        return data;
    },

    async deleteTechnology({ state }, id) {
        const { data } = await axios.delete(`/technologies/${id}`);

        return data;
    },

    async uploadIcon({ state }, { icon }) {
        const iconData = new FormData();
        iconData.append('icon', icon, icon.name);

        const { data } = await axios.post(`/technologies/icon`, iconData);

        return data;
    }
};
