<template>
    <v-footer color="indigo" app>
        <span class="white--text">&copy; Remotecraftsmen 2019</span>
    </v-footer>
</template>

<script>
export default {};
</script>

<style>
</style>