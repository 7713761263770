const menu = [
    {
        text: 'Dashboard',
        icon: 'mdi-home',
        url: '/',
        exact: true
    },
    {
        text: 'Specialists',
        icon: 'mdi-account',
        url: '/specialists'
    },
    {
        text: 'Realizations',
        icon: 'attach_money',
        url: '/realizations'
    },
    {
        text: 'Technologies',
        icon: 'highlight',
        url: '/technologies'
    },
    {
        text: 'Opinions',
        icon: 'face',
        url: '/opinions'
    },
    {
        text: 'Contents',
        icon: 'view_module',
        url: '/contents'
    },
    {
        text: 'Settings',
        icon: 'settings',
        url: '/settings'
    }
];

export default menu;
