import axios from '@/plugins/axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getSettings() {
        const { data } = await axios.get(`/settings`);

        return data;
    },

    async updateSettingsByTags({ state }, { tag, settings }) {
        const { data } = await axios.put('/settings', {
            tag,
            settings
        });

        return data;
    }
};
