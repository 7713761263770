  <template>
    <v-app id="inspire">
        <v-header />
        <v-main>
            <router-view />
        </v-main>
        <v-footer />
    </v-app>
</template>

<script>
import Header from '@/components/layouts/Header';
import Footer from '@/components/layouts/Footer';

export default {
    components: {
        'v-header': Header,
        'v-footer': Footer
    }
};
</script>
